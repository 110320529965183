// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-js": () => import("./../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("./../src/pages/AboutMe.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-baking-weeks-js": () => import("./../src/pages/BakingWeeks.js" /* webpackChunkName: "component---src-pages-baking-weeks-js" */),
  "component---src-pages-contact-info-js": () => import("./../src/pages/ContactInfo.js" /* webpackChunkName: "component---src-pages-contact-info-js" */),
  "component---src-pages-crafts-js": () => import("./../src/pages/Crafts.js" /* webpackChunkName: "component---src-pages-crafts-js" */),
  "component---src-pages-crochet-js": () => import("./../src/pages/Crochet.js" /* webpackChunkName: "component---src-pages-crochet-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-knitting-js": () => import("./../src/pages/Knitting.js" /* webpackChunkName: "component---src-pages-knitting-js" */),
  "component---src-pages-paper-crafts-js": () => import("./../src/pages/PaperCrafts.js" /* webpackChunkName: "component---src-pages-paper-crafts-js" */),
  "component---src-pages-projects-js": () => import("./../src/pages/Projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-resume-js": () => import("./../src/pages/Resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-spinning-js": () => import("./../src/pages/Spinning.js" /* webpackChunkName: "component---src-pages-spinning-js" */),
  "component---src-pages-weaving-js": () => import("./../src/pages/Weaving.js" /* webpackChunkName: "component---src-pages-weaving-js" */)
}

